<template>
  <div class="relative">
    <el-Dropdown
      ref="dropdownRef"
      trigger="click"
      popper-class="summery-dropdown"
      @visible-change="toggle"
    >
      <el-button plain>
        答題狀況
        <span class="text-info-4 ml-1">
          {{ answered }}/ {{ rowsData.total }}</span
        >
      </el-button>

      <template #dropdown class="duration-top-0">
        <div class="bg-white p-4 rounded-md text-base space-y-4 relative">
          <div
            class="absolute right-0 top-0 p-4 cursor-pointer"
            @click="onClose"
          >
            <font-awesome-icon
              :icon="['fas', 'times']"
              class="text-gray text-xl"
            />
          </div>
          <div class="text-center text-primary">
            您一共有 {{ rowsData.total }} 題測驗
          </div>
          <!-- search input -->
          <el-Input
            v-model="filterNo"
            clearable
            :placeholder="`輸入欲前往的題數`"
            class=""
          >
          </el-Input>

          <div class="flex justify-center">
            <el-button
              type="text"
              size="small"
              class="underline"
              :class="type == '' ? 'active' : ''"
              @click="switchType('')"
              >顯示全部</el-button
            >

            <el-button
              v-if="!isResult"
              type="text"
              size="small"
              class="underline"
              :class="type == 'done' ? 'active' : ''"
              @click="switchType('done')"
              >已作答</el-button
            >
            <el-button
              v-if="isResult"
              type="text"
              size="small"
              class="underline"
              :class="type == 'wrong' ? 'active' : ''"
              @click="switchType('wrong')"
              >答錯</el-button
            >
            <el-button
              type="text"
              size="small"
              class="underline"
              :class="type == 'undone' ? 'active' : ''"
              @click="switchType('undone')"
              >未作答</el-button
            >
          </div>

          <!-- list -->
          <div class="border-t">
            <div v-show="rows.length == 0" class="text-gray text-center pt-2">
              查無符合的題數
            </div>
            <ol
              class="grid grid-cols-2 gap-x-6 pr-4 bg-white overflow-y-auto max-h-[200px]"
            >
              <li
                v-for="row in rows"
                :key="row.id"
                class="cursor-pointer flex items-center"
              >
                <Mark
                  v-if="isResult"
                  :status="row.status"
                  :calculate="row.calculate"
                ></Mark>

                <el-button
                  type="text"
                  class="underline mr-1 py-0"
                  @click.prevent="goto(row)"
                >
                  {{ row.no }}.</el-button
                >

                <span
                  class="truncate"
                  :class="
                    row.status === 'wrong' || row.status === 'none'
                      ? 'text-danger'
                      : ''
                  "
                >
                  {{ row.result || '未作答' }}
                </span>
              </li>
            </ol>
            <div v-show="!rows.length == 0" class="text-gray text-center pt-2">
              共 {{ rows.length }} 題
            </div>
          </div>
        </div>
      </template>
    </el-Dropdown>
  </div>
</template>
<script>
import { inject, computed, ref, toRefs } from 'vue';
import { ElDropdown, ElInput } from 'element-plus';

import Mark from './_Mark.vue';
import _ from 'lodash';
export default {
  components: {
    ElDropdown,
    ElInput,
    Mark,
  },
  props: {
    isResult: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isResult } = toRefs(props);
    const { rowsData, total, goto, answerResult, answered, nonCalculates } =
      inject('testerData');

    const testStateDisplay = ref(false);
    const dropdownRef = ref(null);
    const filterNo = ref('');
    const type = ref('');

    const rows = computed(() => {
      let _rows = [];
      const list = _.cloneDeep(answerResult);
      _rows = Array(rowsData.total);
      const defaultData = {
        id: null,
      };
      if (isResult.value) {
        Object.assign(defaultData, {
          status: 'none',
        });
      }
      _rows.fill(defaultData);

      _rows.forEach((row, i) => {
        let _row, id, result, status, calculate, no;

        no = i + 1;

        if (nonCalculates && nonCalculates.length) {
          calculate = nonCalculates.indexOf(no) > -1 ? 0 : 1;
        }

        const item = list.find((x) => x.no == no);

        if (item) {
          if (isResult.value && item.ansStatus) {
            status = item.ansStatus;
          }
          id = item.id;

          if (_.isArray(item.userResult) && item.userResult[0]) {
            const name = item.resultName || item.userResult;
            result = name.join(',');
          }
          if (result) {
            result = result.trim().slice(0, 10) + '';
          }
        }

        _row = {
          no,
          id,
          result,
          status,
          calculate,
        };

        _rows[i] = _row;
      });

      return getFilterResult(_rows);
    });

    const getFilterResult = (rows) => {
      if (type.value == 'done') {
        rows = rows.filter((x) => x.result && x.result.length);
      } else if (type.value == 'undone') {
        rows = rows.filter((x) => !x.result || !x.result.length);
      } else if (type.value == 'wrong') {
        rows = rows.filter((x) => x.status === 'wrong');
      }

      if (filterNo.value) {
        rows = rows.filter((x) => ('' + x.no).indexOf(filterNo.value) != -1);
      }
      return rows;
    };
    const switchType = (_type) => {
      type.value = _type;
      filterNo.value = '';
    };

    // ASK
    // const isLocked = useScrollLock(Window );
    const toggle = (isOpen) => {
      console.log('toggle', isOpen, dropdownRef.value);
      // isLocked.value = isOpen;
    };

    const onClose = () => {
      dropdownRef.value.handleClose();
    };

    return {
      rows,
      answered,
      rowsData,
      total,
      goto,
      testStateDisplay,
      toggle,
      filterNo,
      dropdownRef,
      type,
      onClose,
      switchType,
      nonCalculates,
      // isNoPointsByResult,
    };
  },
};
</script>

<style lang="sass" scoped>
.summery-dropdown
  @screen md
    position: fixed!important
    top: 0!important
    right: 0!important
    bottom: 9rem!important
    left: 0!important
.el-button--text
  &.active
    color: var(--el-color-primary-light-2)
</style>
