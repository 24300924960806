import { ref, computed, inject } from 'vue';

import { useDraggable, useElementBounding, clamp } from '@vueuse/core';
export default () => {
  const draggable = ref();

  const { x, y } = useDraggable(draggable, {
    initialValue: { x: 1000000000, y: 1000000000 },
  });
  const { width, height } = useElementBounding(draggable);

  const padding = 8;

  const restrictedX = computed(() =>
    clamp(
      padding,
      x.value,
      document.documentElement.clientWidth - width.value - padding,
    ),
  );

  const restrictedY = computed(() =>
    clamp(
      padding + 64,
      y.value,
      document.documentElement.clientHeight - height.value - padding,
    ),
  );
  return { restrictedY, restrictedX, draggable };
};
