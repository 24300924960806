import { reactive, ref, computed } from 'vue';
import _ from 'lodash';
import Tester from '@/api/Tester';
export default ({ testId, type }) => {
  const nonCalculates = reactive([]);
  const form = reactive([]);
  const fetcher = () => {
    return Tester.helper({
      testId,
      type,
    }).then((res) => {
      if (res.code === 1) {
        form.push(...res.data.rows);
        if (res.data.nonCalculates && res.data.nonCalculates.length) {
          nonCalculates.push(...res.data.nonCalculates);
        }
      }
      return res;
    });
  };

  const update = ({ id, ans, qType, no }) => {
    const idx = form.findIndex((x) => {
      return x.id === id;
    });

    const row =
      idx > -1 ? form[idx] : { no, id, userResult: null, resultName: null };

    if (qType.id == 1 || qType.id == 2) {
      const old = row.userResult ? row.userResult[0] : [];
      if (ans.id != old) {
        row.userResult = [ans.id];
        row.resultName = [ans.name];
      } else {
        row.userResult = [];
        row.resultName = [];
      }

      // 複選
    } else if (qType.id == 3) {
      if (!row.userResult) {
        row.userResult = [];
      }
      if (!row.resultName) {
        row.resultName = [];
      }
      const optIdx = row.userResult.indexOf(ans.id.toString());
      if (optIdx > -1) {
        row.userResult.splice(optIdx, 1);
        row.resultName.splice(optIdx, 1);
      } else {
        row.userResult.push(ans.id);

        row.userResult.sort((a, b) => a.localeCompare(b));
        const idx = row.userResult.findIndex((x) => x === ans.id);

        if (idx > -1) {
          row.resultName.splice(idx, 0, ans.name);
        }
      }
    } else if (
      qType.id == 4 ||
      qType.id == 5 ||
      qType.id == 6 ||
      qType.id == 7
    ) {
      row.userResult = ans ? ans : [null];

      row.calculate = 0;
    }

    if (idx < 0) {
      form.push(row);
    }
  };

  const getAnsIds = () => {
    let _form = {};

    form.forEach((x) => {
      if (!x.userResult) return;
      _form[x.id] = _.isArray(x.userResult)
        ? x.userResult.join(',')
        : x.userResult;
    });

    return _form;
  };

  const answered = computed(() => {
    let i = 0;

    Object.keys(form).forEach((key) => {
      if (
        !form[key].userResult ||
        !form[key].userResult.length ||
        !form[key].userResult[0]
      )
        return;
      i += 1;
    });
    return i;
    // return Object.keys(form).length;
  });

  return { form, fetcher, update, answered, getAnsIds, nonCalculates };
};
