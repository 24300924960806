<template>
  <div
    :class="{
      'pointer-events-none opacity-30': isFreezing,
      'bg-background-2':
        $route.params.slug === 'topic' || $route.query.type === 'topic',
    }"
  >
    <div class="container space-y-6 py-8">
      <template v-for="row in rowsData.rows" :key="row.id">
        <slot
          :row="row"
          :result="answerResult.find((x) => x.id === row.id)?.userResult"
        >
        </slot>
      </template>
    </div>

    <div
      v-if="rowsData.rows?.length"
      class="flex w-full py-6 justify-center space-x-4"
    >
      <El-Button :disabled="form.pg === 1" class="px-12" @click="prev"
        >上一頁</El-Button
      >

      <El-Button
        :disabled="rowsData.totalPage === form.pg"
        type="primary"
        class="px-12"
        @click="next"
        v-text="rowsData.totalPage === form.pg ? '最後' : '下一頁'"
      ></El-Button>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

export default {
  setup() {
    const { form, rowsData, answerResult, next, prev, isFreezing } =
      inject('testerData');

    return {
      form,
      rowsData,
      next,
      prev,
      isFreezing,

      answerResult,
    };
  },
};
</script>
