<template>
  <font-awesome-icon
    :icon="[
      'fas',
      calculate == 0 ? 'hashtag' : status === 'correct' ? 'check' : 'times',
    ]"
    class="mt-1"
    :class="
      calculate == 0
        ? 'text-gray-500 text-md'
        : status === 'correct'
        ? ' text-info-4 text-lg'
        : 'text-danger text-xl mr-1'
    "
  />
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default: '',
    },
    calculate: {
      type: [Number, Boolean],
      default: 1,
    },
  },
  setup() {},
};
</script>
