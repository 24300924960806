<template>
  <El-Select v-model="model" class="w-[9em]" placeholder="Select">
    <El-Option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="item.id"
      :disabled="model === item.id"
    >
    </El-Option>
  </El-Select>
</template>
<script>
import { ElSelect, ElOption } from 'element-plus';
import { computed, inject } from 'vue';
import { MODES } from '@/utils/Config.js';

export default {
  components: { ElSelect, ElOption },
  setup() {
    const { form, updateMode } = inject('testerData');

    const isMobile = inject('isMobile');

    const options = isMobile ? [MODES[0]] : [...MODES];

    const model = computed({
      get() {
        return form.mode;
      },
      set(val) {
        updateMode(val);
      },
    });
    return { model, options };
  },
};
</script>
