import {
  computed,
  inject,
  reactive,
  ref,
  watch,
  nextTick,
  onMounted,
  onUpdated,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Tester from '@/api/Tester';
import Conf from '@/api/Conf';

import _ from 'lodash';
import { isEmpty, grouper, isMobile } from '@/utils';
import { ElMessage } from 'element-plus';
import MessageBox from '@/components/messageBox/Index';
import { MODES } from '@/utils/Config';
import useSaved from '@/modules/saved/useSaved';
export default ({ API, testId, defaultFrom, cb }) => {
  const route = useRoute();

  const router = useRouter();
  const pageSize = 5;

  const _isMobile = isMobile();

  const {
    form: saveds,
    update: updateSaved,
    set: setSaveds,
  } = useSaved({ api: 'question' });

  const form = reactive({
    mode: _isMobile ? MODES[0].id : route.query['mode'] || MODES[2].id,
    size: _isMobile
      ? MODES[0].size
      : parseInt(route.query['size']) || MODES[2].size,
    cur: parseInt(route.query['cur']) || 1,
    pg: parseInt(route.query['pg']) || 1,

    ...defaultFrom,
  });

  const rowsData = reactive({
    count: 0,
    total: 0,
    totalPage: 0,
    rows: [],
    answered: 0,
  });

  const dataType = reactive([]);
  const loading = ref(false);

  const needScrollTo = ref(null);
  const updateRows = () => {
    if (form.size !== 1) {
      grouper(rowsData.rows);
    }
    updateTotalPage();
    setTimeout(() => {
      if (needScrollTo.value) {
        scrollToNo(needScrollTo.value);
        needScrollTo.value = null;
      }
    }, 0);
  };

  const fetcher = () => {
    loading.value = true;
    return API({
      testId: testId,
      pg: form.pg,
      size: form.size,
    }).then((res) => {
      if (res.code === 1) {
        if (res.data.type) {
          router.push({
            query: { ...route.query, type: res.data.type },
          });
        }
        rowsData.total = res.data.all;
        rowsData.rows = [...res.data.rows];
        setSaveds(res.data.rows);
        updateRows();
      } else {
        console.log('no content!');
      }
      if (cb && typeof cb === 'function') {
        cb(res);
      }

      loading.value = false;
      return res;
    });
  };

  const top = () => {
    window.scrollTo(0, 0);
  };

  const scrollToNo = (no) => {
    const element = document.getElementById('quest-' + no);

    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset - 60;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const goto = (opt) => {
    if (form.size === 1) {
      form.pg = opt.no;
    } else {
      if (form.pg === Math.ceil(opt.no / form.size)) {
        scrollToNo(opt.no);
      } else {
        form.pg = Math.ceil(opt.no / form.size);

        needScrollTo.value = opt.no;
      }
    }
  };

  const updateCur = () => {
    let nodeList = document.querySelectorAll('.quest');
    let elements = Array.from(nodeList);
    if (elements) {
      const el = elements.find((x) => {
        return x.getBoundingClientRect().y >= 0;
      });
      if (el) {
        form.cur = el.id.split('-').slice(-1)[0];
      }
    }
  };

  const updateTotalPage = () => {
    const totalPage = Math.ceil(rowsData.total / form.size);
    rowsData.totalPage = !totalPage ? 1 : totalPage;
  };

  const updateMode = (_mode) => {
    // 離開模式時紀錄
    updateCur();

    form.mode = _mode;
    form.size = MODES.find((x) => x.id === _mode).size;
    if (form.size === 1) {
      form.pg = Number(form.cur);
      return;
    } else {
      form.pg = Math.ceil(form.cur / form.size);
    }
    needScrollTo.value = form.cur;

    ElMessage({ message: '閱讀模式修改成功', type: 'success' });
  };

  const first = () => {
    form.pg = 1;
    top();
  };

  const more = () => {
    form.pg += 1;
  };

  const next = () => {
    if (form.pg >= rowsData.totalPage) return;
    form.pg += 1;
    top();
  };
  const prev = () => {
    form.pg = form.pg > 1 ? +form.pg - 1 : form.pg;
    top();
  };

  const syncQuery = () => {
    router.push({
      query: { ..._.omitBy({ ...route.query, ...form }, isEmpty) },
    });
  };

  const updateConf = ({ id, conf }) => {
    const idx = rowsData.rows.findIndex((x) => {
      return x.id === id;
    });
    if (rowsData.rows[idx].conf == conf) return;
    rowsData.rows[idx].conf = conf;
  };

  const onFromChange = (params) => {
    syncQuery();
    fetcher();
  };

  // 監視 form
  watch(
    () => _.cloneDeep(form),
    (val, old) => {
      nextTick(() => {
        if (!_.isEqual(val, old)) {
          onFromChange();
        }
      });
    },
    { immediate: false },
  );

  return {
    form,
    // syncForm,
    rowsData,

    dataType,
    fetcher,
    more,
    top,
    updateRows,
    updateMode,
    updateConf,
    saveds,
    updateSaved,
    next,
    prev,
    goto,
    first,
    loading,
  };
};
